<template>
	<div class="content">
		<div class="main">
			<img class="ts-pic" src="../../assets/images/common/xz-tishi.png" >
			<div class="xz-code">
				<img src="../../assets/images/common/ewm.jpg" >
			</div>
			<div class="xztext font16"><span>扫码下载</span></div>
		</div>
	</div>
	
</template>

<script>
export default {
	data() {
		return {
			
			
			
		};
	},
	created() {},
	mounted() {},
	methods: {
		
		
	}
};
</script>

<style lang="less" scoped>
.content{
	width: 100%;
	height: 1024px;
	background: url(../../assets/images/common/down-app.png) no-repeat;
	background-size: 100% 100%;
	position: relative;
	.main{
		position: absolute;
		top: 250px;
		right: 400px;
		.ts-pic{
			width: 220px;
			height: 180px;
		}
		.xz-code{
			margin-top: 50px;
			img{
				width: 180px;
				height: 180px;
				background-color: #FFFFFF;
			}
			
		}
		.xztext{
			margin-top: 30px;
			span{
				background-color: #FFFFFF;
				color: #111111;
				border-radius: 50px;
				padding: 5px 40px;
			}
		}
	}
}

</style>
